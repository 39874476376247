const paths = {
  root: '/',
  login: '/login',
  checkEmail: '/check-email',
  passwordChanged: '/password-changed',
  register: '/register',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  paymentComplete: '/payment-complete',
  notFound: '/not-found',
  appsManagement: '/apps-management',
  leaderboard: '/leaderboard',
  testing: '/test',
  dashboard: '/dashboard',

  onboarding: {
    root: '/onboarding',
    userPath: ':userPath',
  },

  pricing: {
    root: '/pricing',
    appType: '/pricing/:appType?',
  },

  practiceMaterials: {
    root: '/practice-materials',
    myNotes: 'my-notes',
  },

  knowledgeLibrary: {
    root: '/knowledge-library',
    section: 'section/:entitlementId/:sectionId',
    chapter: 'chapter/:entitlementId/:sectionId?/:chapterId',
    edit: 'chapter/:entitlementId/:sectionId/:chapterId/edit',
  },

  videoLibrary: {
    root: '/video-library',
    section: 'section/:entitlementId/:sectionId?',
    video: 'video/:entitlementId/:sectionId?/:videoId?',
  },

  flashcards: {
    root: '/flashcards',
    quiz: 'quiz/:todoId/:markId?/:cardId?/:cardNumber?',
    summary: 'quiz/summary/:todoId',
    presetShareLink: 'preset/share/:productType/:presetId',
  },

  mockTests: {
    root: '/mock-tests',
    intro: 'intro/:mockTestId',
    summary: 'test/summary/:mockTestId/:marksheetId',
    test: 'test/:mockTestId/:marksheetId/:markId?/:questionId?',
  },

  questions: {
    root: '/questions',
    soloQuizBuilder: 'solo/quiz/builder',
    soloQuiz: 'solo/quiz/:marksheetId/:markId?/:questionId?/:questionNumber?',
    soloQuizSummary: 'solo/quiz/summary/:marksheetId',
    groupLobby: 'group',
    groupQuizBuilder: 'quiz/builder/:sessionId/:marksheetId',
    groupQuizSummary: 'quiz/summary/:sessionId/:marksheetId',
    groupQuiz:
      'quiz/:sessionId/:marksheetId/:markId?/:questionId?/:questionNumber?',
    question: 'question/:questionId',
    review: 'review/:marksheetId/:markId?/:questionId?/:questionNumber?',
    flagged: 'flagged/:markId?/:questionId?/:questionNumber?',
    presetShareLink: 'preset/share/:productType/:presetId',
  },

  stations: {
    root: '/stations',
    review: 'review/:osceMarksheetId',
    soloStudybuilder: 'solo/builder',
    soloStudy: 'solo/study/:osceMarksheetId',
    soloStudySummary: 'solo/study/summary/:osceMarksheetId',
    groupLobby: 'group',
    groupStudyBuilder: 'builder/:sessionId/:osceMarksheetId',
    groupStudySummary: 'study/summary/:sessionId/:osceMarksheetId',
    groupStudy: 'study/:sessionId/:osceMarksheetId',
    waitingRoom: 'study/waiting-room/:sessionId/:osceMarksheetId',
  },

  mlaContentMap: {
    root: '/mla-content-map',
  },

  sample: {
    root: '/sample/:productType/:exerciseType',
    questions: 'solo/quiz/:marksheetId/:markId?/:questionId?/:questionNumber?',
    questionsSummary: 'solo/quiz/summary/:marksheetId',
    stations: 'solo/study/:osceMarksheetId',
    stationsSummary: 'solo/study/summary/:osceMarksheetId',
    mockTests: 'test/:mockTestId/:marksheetId/:markId?/:questionId?',
    mockTestsSummary: 'test/summary/:mockTestId/:marksheetId',
  },
};

export default paths;
