import {
  BreakpointsOptions,
  Mixins,
  PaletteOptions,
} from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import ModeratRegular from 'assets/fonts/Moderat-Regular.woff2';
import ModeratMedium from 'assets/fonts/Moderat-Medium.woff2';
import ModeratCondensedBold from 'assets/fonts/Moderat-Condensed-Bold.woff2';
import { Elevation } from 'types';
import { CONTENT_MAX_WIDTH } from 'config/constants';

export const BREAKPOINTS: BreakpointsOptions = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl'],
  values: {
    xs: 0,
    sm: 360,
    md: 720,
    lg: 1280,
    xl: 1366,
    xxl: 1680,
    xxxl: 1920,
  },
};

export const WHITE = '#ffffff';
export const BLACK = '#000000';
export const DARK_BACKGROUND = '#1b1c1f';
export const TRANSPARENCY = 'rgba(255,255,255,0)';

export const COLORS: Omit<
  PaletteOptions,
  | 'icon'
  | 'stroke'
  | 'toggle'
  | 'disabled'
  | 'contrast'
  | 'calculator'
  | 'chart'
  | 'image'
  | 'products'
  | 'dashboardWidget'
  | 'opacity'
  | 'tooltip'
  | 'mockTest'
> = {
  amber: {
    [50]: '#fff8e1',
    [100]: '#ffecb3',
    [200]: '#ffe082',
    [300]: '#ffd54f',
    [400]: '#ffca28',
    [500]: '#ffc107',
    [600]: '#ffb300',
    [700]: '#ffa000',
    [800]: '#ff8f00',
    [900]: '#ff6f00',
  },
  innovativeBlue: {
    [50]: '#edf2ff',
    [100]: '#dde6ff',
    [200]: '#baccff',
    [300]: '#97b2ff',
    [400]: '#7599ff',
    [500]: '#5380ff',
    [600]: '#4266cc',
    [700]: '#314c99',
    [800]: '#213366',
    [900]: '#101933',
  },
  strongBlack: {
    [50]: '#f9fafa',
    [100]: '#f1f2f3',
    [200]: '#dadce0',
    [300]: '#c7cad0',
    [400]: '#b5b8c1',
    [500]: '#7d8392',
    [600]: '#5d626e',
    [700]: '#3e414a',
    [800]: '#2e3137',
    [900]: '#101012',
  },
  vitalityRed: {
    [50]: '#fff3f5',
    [100]: '#ffcdd2',
    [200]: '#ef9a9a',
    [300]: '#e57373',
    [400]: '#ef5350',
    [500]: '#f44336',
    [600]: '#e53935',
    [700]: '#d32f2f',
    [800]: '#c62828',
    [900]: '#b71c1c',
  },
  brainPink: {
    [50]: '#fdf7f7',
    [100]: '#fcf0ee',
    [200]: '#f9e1de',
    [300]: '#f5d1cd',
    [400]: '#f2c2bd',
    [500]: '#efb3ac',
    [600]: '#bf8f8a',
    [700]: '#8f6b67',
    [800]: '#604845',
    [900]: '#302422',
  },
  calmingBlue: {
    [50]: '#f9fcfd',
    [100]: '#f3fafc',
    [200]: '#e8f5f8',
    [300]: '#dceff5',
    [400]: '#d1eaf1',
    [500]: '#c5e5ee',
    [600]: '#9eb7be',
    [700]: '#76898f',
    [800]: '#4f5c5f',
    [900]: '#272e39',
  },
  reliableGreen: {
    [50]: '#eff6f5',
    [100]: '#cce1de',
    [200]: '#99c4bf',
    [300]: '#66a69e',
    [400]: '#33897e',
    [500]: '#006b5e',
    [600]: '#00564b',
    [700]: '#004038',
    [800]: '#002b26',
    [900]: '#001513',
  },
  encouragingLime: {
    [50]: '#fbfef5',
    [100]: '#f6fdec',
    [200]: '#edfad8',
    [300]: '#e4f8c5',
    [400]: '#dbf5b1',
    [500]: '#d2f39e',
    [600]: '#a8c27e',
    [700]: '#7e925f',
    [800]: '#54613f',
    [900]: '#2a3120',
  },
};

export const ELEVATION: Elevation = [
  'none',
  '0px 2px 5px -1px rgba(16, 16, 18, 0.1), 0px 0px 10px rgba(16, 16, 18, 0.08), 0px 1px 18px rgba(16, 16, 18, 0.04)',
  '0px 2px 6px rgba(124, 128, 158, 0.3)',
  '0 4px 4px rgba(0,0,0,0.4)',
];

export const MIXINS: Mixins = {
  appBar: {
    mobile: {
      minHeight: 56,
    },
    desktop: {
      minHeight: 64,
    },
  },
  modal: {
    width: {
      sm: { maxWidth: 425 },
      md: { maxWidth: 583 },
      lg: { maxWidth: 720 },
      xl: { maxWidth: 1344 },
    },
    maxContentHeight: {
      sm: { maxHeight: 568 },
      md: { maxHeight: 680 },
      lg: { maxHeight: 800 },
    },
  },
  selectionTable: {
    minWidth: 1320,
  },
  sidebar: {
    expanded: {
      minWidth: 256,
    },
    collapsed: {
      minWidth: 64,
    },
  },
  toolbar: {
    minHeight: 56,
    '@media(min-width: 720px)': {
      minHeight: 64,
    },
  },
  container: {
    maxWidth: 1344,
  },
  content: {
    maxWidth: CONTENT_MAX_WIDTH,
  },
  controlPanel: {
    expanded: {
      minWidth: 360,
    },
    collapsed: {
      minWidth: 40,
    },
  },
  bottombar: {
    height: 80,
    minHeight: 64,
    maxHeight: 128,
  },
  progressbar: {
    minHeight: 48,
  },
  quizProgressBar: {
    maxWidth: CONTENT_MAX_WIDTH + 120,
  },
  overlay: {
    default: {
      position: 'absolute',
      display: 'block',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      // The usage of strongBlack is on purpose, this color is constant in
      // both theme: dark and light. This was discussed with the designer
      backgroundColor: COLORS.strongBlack[700],
      opacity: 0,
      transition: 'opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', // the same as transitions.create(['opacity']) but we can't use transitions.create outside of components
    },
    hover: {
      opacity: 0.4,
    },
  },
  dashboardWidget: {
    mobile: {
      maxHeight: 450,
      maxWidth: 384,
      padding: 16,
      paddingBottom: 40,
    },
    desktop: {
      maxHeight: 450,
      maxWidth: 384,
      padding: 24,
      paddingBottom: 40,
    },
    title: {
      mobile: 72,
      desktop: 88,
    },
    gridMinMax: 'minmax(386px, 432px)',
  },
  mockTest: {
    footer: {
      minHeight: 64,
    },
    toolBar: {
      minHeight: 40,
    },
    container: {
      maxWidth: 1920,
    },
    rightSideBar: {
      minWidth: 850,
    },
  },
  exerciseSummary: {
    content: {
      maxWidth: 1344,
    },
  },
};

export const TYPOGRAPHY: TypographyOptions = {
  fontFamily: 'Moderat-Regular',
  h1: {
    fontFamily: 'Moderat-Regular',
    fontWeight: 400,
    fontSize: '60px',
    lineHeight: '72px',
    '@media (min-width: 720px)': {
      fontSize: '96px',
      lineHeight: '112px',
    },
  },
  h2: {
    fontFamily: 'Moderat-Regular',
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '64px',
    '@media (min-width: 720px)': {
      fontSize: '60px',
      lineHeight: '72px',
    },
  },
  h3: {
    fontFamily: 'Moderat-Regular',
    fontWeight: 400,
    fontSize: '34px',
    lineHeight: '48px',
    '@media (min-width: 720px)': {
      fontSize: '48px',
      lineHeight: '64px',
    },
  },
  h4: {
    fontFamily: 'Moderat-Regular',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32px',
    '@media (min-width: 720px)': {
      fontSize: '34px',
      lineHeight: '48px',
    },
  },
  h5: {
    fontFamily: 'Moderat-Regular',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 400,
    '@media (min-width: 720px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  h6: {
    fontFamily: 'Moderat-Regular',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
    '@media (min-width: 720px)': {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  body1: {
    fontFamily: 'Moderat-Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  body1Medium: {
    fontFamily: 'Moderat-Medium',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  body2: {
    fontFamily: 'Moderat-Regular',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
  },
  body2Medium: {
    fontFamily: 'Moderat-Medium',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
  },
  caption: {
    fontFamily: 'Moderat-Regular',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  overline: {
    fontFamily: 'Moderat-Regular',
    fontSize: '10px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
  },
  button: {
    fontFamily: 'Moderat-Condensed-Bold',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
  },
};

export const FONT_FAMILY = `
@font-face {
  font-family: "Moderat-Regular";
  src: local('Moderat-Regular'), local('Moderat-Regular'), url(${ModeratRegular}) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Moderat-Medium";
  src: local('Moderat-Medium'), local('Moderat-Medium'), url(${ModeratMedium}) format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Moderat-Condensed-Bold";
  src: local('Moderat-Condensed-Bold'), local('Moderat-Condensed-Bold'), url(${ModeratCondensedBold}) format("woff2");
  font-weight: 700;
  font-style: normal;
}
`;
