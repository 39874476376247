import React, { forwardRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import View from './View';
import { Children } from 'types';
import { useLabValuesState } from 'components/LabValues';

interface ContentProps {
  activetab: string;
  quizprogressbar: 1 | 0;
}

const Content = styled(Box)<ContentProps>(
  ({
    activetab,
    quizprogressbar,
    theme: {
      mixins: { progressbar, appBar, bottombar },
      spacing,
      breakpoints,
    },
  }) => {
    const reducedMinHeight =
      activetab !== 'Markscheme'
        ? Number(bottombar.minHeight) - 26
        : bottombar.minHeight;

    const reducedMaxHeight =
      activetab !== 'Markscheme'
        ? Number(bottombar.maxHeight) - 80
        : bottombar.maxHeight;

    return {
      display: 'flex',
      alignItems: 'stretch',
      height: `calc(100dvh - ${appBar.mobile.minHeight}px - ${
        quizprogressbar ? 0 : progressbar.minHeight
      }px - ${quizprogressbar ? bottombar.height : reducedMinHeight}px - 1px)`,
      maxHeight: `calc(100dvh - ${appBar.mobile.minHeight}px - ${
        quizprogressbar ? 0 : progressbar.minHeight
      }px - ${quizprogressbar ? bottombar.height : reducedMaxHeight}px - 1px)`,
      boxSizing: 'border-box',
      overflowY: 'auto',
      padding: spacing(4, 0),

      [breakpoints.up('md')]: {
        padding: spacing(6, 0),
        height: quizprogressbar
          ? '100%'
          : `calc(100dvh - ${appBar.desktop.minHeight}px - ${progressbar.minHeight}px - ${bottombar.minHeight}px - 1px)`,
        maxHeight: quizprogressbar
          ? '100%'
          : `calc(100dvh - ${appBar.desktop.minHeight}px - ${progressbar.minHeight}px - ${bottombar.minHeight}px - 1px)`,
      },

      [breakpoints.up('lg')]: {
        padding: spacing(6, 0),
      },
    };
  }
);

interface ExerciseContentProps {
  activeTab?: string;
  children: Children;
  quizProgressBar?: boolean;
}

const ExerciseContent = forwardRef(
  (
    {
      activeTab = 'Markscheme',
      children,
      quizProgressBar,
    }: ExerciseContentProps,
    ref
  ): JSX.Element => {
    const { hideLabValues, showLabValues } = useLabValuesState();

    useEffect(() => {
      showLabValues();

      return () => {
        hideLabValues();
      };
    }, [hideLabValues, showLabValues]);

    return (
      <Content
        activetab={activeTab}
        quizprogressbar={quizProgressBar ? 1 : 0}
        ref={ref}
      >
        <View>{children}</View>
      </Content>
    );
  }
);

ExerciseContent.displayName = 'ExerciseContent';

export default ExerciseContent;
