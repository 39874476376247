import { EProductType } from '@quesmed/types-rn/models';
import React, { SVGProps } from 'react';
import clsx from 'clsx';

import {
  ProductBundle,
  ProductOne,
  ProductThree,
  ProductTwo,
} from './components';
import { Nullable } from 'types';

const getProductImage = (product: EProductType) => {
  switch (product) {
    case EProductType.QBANK:
    case EProductType.MEDICAL_SCIENCES:
    case EProductType.MRCP_PART1:
    case EProductType.MSRA:
    case EProductType.INTERVIEW_ANAESTHETICS:
    case EProductType.INTERVIEW_PAEDIATRICS:
    case EProductType.PLAB1:
    default:
      return ProductOne;
    case EProductType.OSCE:
    case EProductType.ANATOMY:
    case EProductType.MRCP_PART2:
    case EProductType.INTERVIEW_CST:
    case EProductType.PLAB2:
      return ProductTwo;
    case EProductType.PACES:
    case EProductType.INTERVIEW_IMT:
      return ProductThree;
    case EProductType.BUNDLE:
    case EProductType.ANATOMY_BUNDLE:
    case EProductType.INTERVIEW_RADIOLOGY:
      return ProductBundle;
  }
};

interface ProductImageProps extends SVGProps<SVGSVGElement> {
  product: Nullable<EProductType>;
  variant?: 'subscription' | 'product';
}

export const ProductImage = ({
  product,
  className,
  variant = 'product',
  ...props
}: ProductImageProps): Nullable<JSX.Element> => {
  if (!product) {
    return null;
  }

  const Image = getProductImage(product);
  const imageClassName = `product-${product}`;

  return (
    <Image className={clsx(variant, imageClassName, className)} {...props} />
  );
};
