import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import { useMarkdownTextScale } from './MarkdownTextSizeSlider';
import { constSize } from 'utils';
import { DEFAULT_TEXT_SCALE } from 'config/constants';

const INIT_BULLET_SIZE = 4;
const INIT_BULLET_TOP = 11;

interface ParsedContentStylesProps {
  bottomoffset: 1 | 0;
  iscrossout?: 1 | 0;
  scale: number;
  ispricing?: 1 | 0;
}

export const MarkdownTableBox = styled(Box)(({ theme: { spacing } }) => ({
  overflowX: 'auto',
  width: '100%',
  marginBottom: spacing(4),
  marginTop: spacing(4),
}));

const ParsedContentStyles = styled(Box)<ParsedContentStylesProps>(
  ({
    theme: {
      palette,
      spacing,
      typography: { body1, body2, body1Medium, body2Medium, button },
      transitions,
    },
    bottomoffset,
    iscrossout,
    scale,
    ispricing,
  }) => ({
    '--font-size-1': `calc(${body1.fontSize} * ${scale})`,
    '--font-size-2': `calc(${body2.fontSize} * ${scale})`,
    '--line-size-1': `calc(${body1.lineHeight} * ${scale})`,
    '--line-size-2': `calc(${body2.lineHeight} * ${scale})`,
    '--bullet-size': `${INIT_BULLET_SIZE * scale}px`,
    '--bullet-top': `${INIT_BULLET_TOP * scale}px`,
    textDecoration: iscrossout ? 'line-through' : 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4 * scale, 0),
    paddingBottom: bottomoffset ? '24px' : 0,
    color: palette.text.primary,

    'h2, h3, h4, h5, h6': {
      ...body1Medium,
      fontSize: 'var(--font-size-1)',
      lineHeight: 'var(--line-size-1)',
      fontWeight: 700,
    },

    h1: {
      fontSize: scale ? `calc(${button.fontSize} * ${scale})` : button.fontSize,
      lineHeight: scale
        ? `calc(${button.lineHeight} * ${scale})`
        : button.lineHeight,
      margin: 0,
    },

    'ul, ol': {
      paddingLeft: ispricing ? 0 : spacing(3 * scale),
    },

    li: {
      position: 'relative',
      paddingLeft: spacing(3.5 * scale),
      ...body1Medium,
      fontSize: 'var(--font-size-1)',
      lineHeight: 'var(--line-size-1)',
    },

    'li > ul': {
      paddingLeft: spacing(4 * scale),
    },

    'li::before': {
      content: "''",
      display: 'block',
      ...constSize('var(--bullet-size)'),
      top: 'var(--bullet-top)',
      borderRadius: '50%',
      position: 'absolute',
      left: 0,
      background: palette.text.primary,
    },

    'li > p': {
      margin: 0,
    },

    'p, ul, li': {
      ...(ispricing ? { ...body2Medium } : { ...body1Medium }),
      fontSize: ispricing ? 'initial' : 'var(--font-size-1)',
      lineHeight: ispricing ? 'initial' : 'var(--line-size-1)',
    },

    table: {
      width: '100%',
      background: palette.background.paper,

      '& > thead:has(> tr > th:empty):not(:has(> tr > th:not(:empty)))': {
        display: 'none',
      },
    },

    'table, th, td': {
      border: `1px inset ${palette.stroke.main}`,
      borderCollapse: 'collapse',
      borderSpacing: 0,
    },

    tr: {
      width: '100%',
    },

    th: {
      padding: spacing(3 * scale),
      textAlign: 'left',
      ...body2Medium,
      fontSize: 'var(--font-size-2)',
      lineHeight: 'var(--line-size-2)',
    },

    td: {
      padding: spacing(3 * scale),
      ...body2,
      fontSize: 'var(--font-size-2)',
      lineHeight: 'var(--line-size-2)',
    },

    a: {
      ...body1Medium,
      fontSize: 'var(--font-size-1)',
      lineHeight: 'var(--line-size-1)',
      textDecoration: 'underline',
      color: palette.text.primary,
      transition: transitions.create(['color']),

      '&:hover': {
        color: palette.primary.dark,
      },
    },
  })
);

export interface MarkdownContentStylesProps {
  children?: ReactNode | ReactNode[];
  bottomOffset?: boolean;
  isCrossOut?: boolean;
  scallable?: boolean;
  isPricing?: boolean;
  className?: string;
  onMouseUpOrTouchEnd?: () => void;
  defaultScale?: number;
}

export const MarkdownContentStyles = ({
  children,
  bottomOffset = true,
  isCrossOut = false,
  scallable = true,
  isPricing = false,
  className,
  onMouseUpOrTouchEnd,
  defaultScale = DEFAULT_TEXT_SCALE,
}: MarkdownContentStylesProps): JSX.Element => {
  const { scale } = useMarkdownTextScale();

  return (
    <ParsedContentStyles
      bottomoffset={bottomOffset ? 1 : 0}
      className={clsx('markdown-reading', className)}
      iscrossout={isCrossOut ? 1 : 0}
      ispricing={isPricing ? 1 : 0}
      onMouseUp={onMouseUpOrTouchEnd}
      onTouchEnd={onMouseUpOrTouchEnd}
      scale={scallable ? scale : defaultScale}
    >
      {children}
    </ParsedContentStyles>
  );
};
