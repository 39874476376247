import React, {
  ComponentPropsWithoutRef,
  ElementType,
  FC,
  memo,
  ReactElement,
  useCallback,
} from 'react';
import ReactMarkdown, { ExtraProps, Options } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import isNil from 'lodash/isNil';
import { ETopicType, IHighlightNode } from '@quesmed/types-rn/models';

import { ConceptName } from './ConceptName';
import { Children } from 'types';
import { Image, LightboxImage } from 'components/Lightbox';
import {
  MarkdownContentStyles,
  MarkdownContentStylesProps,
  MarkdownTableBox,
} from './MarkdownContentStyles';
import ProductFeatureListItem from 'pages/Pricing/ProductFeatureListItem';
import { IHandleSelection, selectText } from 'utils';

const parseImageIndex = (index?: string) => {
  if (!isNil(index)) {
    return Number(index);
  }
};

type MarkdownWrapper = FC<{ children: Children }>;

export interface MarkdownProps
  extends Omit<Options, 'children'>,
    Partial<IHandleSelection> {
  text: string;
  wrapper?: MarkdownWrapper;
  withLightbox?: boolean;
  markdownStylesProps?: MarkdownContentStylesProps;
  showCaption?: boolean;
  simpleImage?: boolean;
  onHighlightClick?: (index: number) => void;
  isPricing?: boolean;
  enableTextSelection?: boolean;
  onMouseUp?: (highlights: IHighlightNode[] | null) => void;
}

export interface MarkProps {
  children: ReactElement[];
  id: string;
}

type MarkElementProps<Element extends ElementType> =
  ComponentPropsWithoutRef<Element> & ExtraProps;

const Markdown = ({
  text,
  wrapper: Wrapper,
  markdownStylesProps,
  onHighlightClick,
  simpleImage = false,
  withLightbox = true,
  showCaption = true,
  isPricing = false,
  enableTextSelection = false,
  highlights,
  onMouseUp,
  fullMarkdown,
}: MarkdownProps): JSX.Element => {
  const handleMouseUp = () => {
    if (onMouseUp && fullMarkdown && enableTextSelection && highlights) {
      const newHighlights = selectText({
        fullMarkdown,
        highlights,
      });
      if (newHighlights) {
        onMouseUp(newHighlights);
      }
    }
  };

  const handleMarkClick = useCallback(
    (id: string | undefined) => () => {
      if (id && onHighlightClick) {
        onHighlightClick(Number(id));
      }
    },
    [onHighlightClick]
  );

  const content = (
    <ReactMarkdown
      components={{
        img: ({ node, src, alt, title: pictureIndex, ...props }) => {
          // we use title attr to quickly and in efficient way pass
          // pictureIdx to the parser to avoid installing additional libs

          // User added image alt within editor = '1.00'
          if ((simpleImage && src) || alt === '1.00') {
            return <Image alt={alt} src={src} />;
          }

          if (src) {
            return (
              <LightboxImage
                caption={alt}
                index={parseImageIndex(pictureIndex)}
                showCaption={showCaption}
                src={src}
                withLightbox={withLightbox}
                {...props}
              />
            );
          }

          return null;
        },
        mark: ({ children, id }: MarkElementProps<'mark'>) => (
          <mark onClick={handleMarkClick(id)}>{children}</mark>
        ),
        u: ({ children, ...props }) => {
          if (children) {
            return props?.['data-chapterid'] ? (
              <ConceptName
                chapterId={props?.['data-chapterid'] as string}
                conceptId={props?.['data-conceptid'] as string}
                topicId={props?.['data-topicid'] as string}
                typeId={props?.['data-typeid'] as unknown as ETopicType}
              >
                {children}
              </ConceptName>
            ) : (
              <span>{children}</span>
            );
          }

          return null;
        },
        table: ({ children, ...props }) => (
          <MarkdownTableBox>
            <table {...props}>{children}</table>
          </MarkdownTableBox>
        ),
        li: ({ children, ...props }) => {
          return isPricing ? (
            <ProductFeatureListItem {...props}>
              {children}
            </ProductFeatureListItem>
          ) : (
            <li {...props}>{children}</li>
          );
        },
      }}
      rehypePlugins={[rehypeRaw, [rehypeExternalLinks, { target: '_blank' }]]}
      remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
      urlTransform={url => url}
    >
      {text}
    </ReactMarkdown>
  );

  return (
    <MarkdownContentStyles
      {...markdownStylesProps}
      onMouseUpOrTouchEnd={enableTextSelection ? handleMouseUp : undefined}
    >
      {Wrapper ? <Wrapper>{content}</Wrapper> : content}
    </MarkdownContentStyles>
  );
};

const compareMarkdownProps = (a: MarkdownProps, b: MarkdownProps) =>
  a.showCaption === b.showCaption &&
  a.text === b.text &&
  a.markdownStylesProps === b.markdownStylesProps;

export default memo(Markdown, compareMarkdownProps);
