import { InMemoryCache, PossibleTypesMap, TypePolicies } from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';

import { mergeConcepts } from 'utils';

const typePolicies: TypePolicies = {
  Query: {
    fields: {
      restricted: {
        merge: true,
      },
      admin: {
        merge: true,
      },
    },
  },
  Topic: {
    fields: {
      concepts: {
        read(existing) {
          return existing && [...existing];
        },
        merge: mergeConcepts,
      },
      entitlement: {
        read(entitlement = {}) {
          return entitlement;
        },
      },
    },
    keyFields: ['id', 'entitlement'],
  },
  Chapter: {
    fields: {
      explanation: {
        read(explanation = '') {
          return explanation;
        },
      },
    },
    keyFields: ['id', 'explanation'],
  },
  Picture: {
    keyFields: ['id', 'index'],
  },
  Video: {
    merge: false,
  },
  Preset: {
    merge: false,
  },
  dashboardPastQuizzesInfo: {
    keyFields: ['marksheetId'],
  },
  scoreRecall: {
    keyFields: false,
  },
  addToDailyStack: {
    keyFields: false,
  },
  contactUs: {
    keyFields: false,
  },
};
/**
 * REF:- https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
 * https://github.com/apollographql/apollo-client/issues/9904
 */
const possibleTypes: PossibleTypesMap = {
  Question: [
    'QuestionPrescription',
    'QuestionSelect3',
    'QuestionEMQ',
    'QuestionRanking',
    'QuestionMultiA',
    'QuestionQA',
    'QuestionSBA',
  ],
  PrescriptionType: ['Dose', 'Drug', 'Route', 'Duration', 'Frequency', 'Unit'],
};

export const cache = new InMemoryCache({
  typePolicies,
  possibleTypes,
});

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});
